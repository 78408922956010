.br__novel {
  min-height: 100vh;
  padding: 0 5%;
}
.br__novel__sc__one {
  padding: 20px 0;
  display: flex;
  align-items: center;
  width: 100%;
}
.br__novel__sc__one > h1 {
  font-size: 20px;
  font-weight: 600;
}
.br__novel__sc__one__span {
  margin-left: auto;
  display: flex;
  gap: 20px;
}
.br__novel__sc__one__span__button {
  border: 1px solid var(--color-primary-accent);
  color: var(--color-primary-accent);
  border-radius: var(--border-radius-medium);
  padding: 10px 15px;
  background: white;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  cursor: pointer;
  margin: 0;
}
.br__novel__sc__one__span__button.alt {
  border: 0;
  color: white;
  background: var(--color-primary-accent);
  cursor: pointer;
}
.br__novel__sc__two {
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.br__novel__sc__two__button {
  border: 0;
  color: black;
  padding: 7.5px 15px;
  background: #d9d9d9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  cursor: pointer;
}
.br__novel__sc__three {
  width: 100%;
}
.admin-table-container {
  margin: 30px;
}

.admin-table {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-border);
  border-radius: 20px;
}

.admin-table-header,
.admin-table-row {
  width: 100%;
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #f7f5ff;
}

.admin-table-header {
  background-color: #f7f5ff;
  font-weight: bold;
}
.admin-table-body {
  min-height: 80vh;
}
/* .admin-table-body .admin-table-row:nth-child(even) {
  background-color: #f7f5ff;
} */

/* Custom checkbox styling */
.admin-table-cell input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid var(--color-primary-accent);
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  outline: none;
}

.admin-table-cell input[type="checkbox"]:checked {
  background-color: var(--color-primary-accent);
}

.admin-table-cell input[type="checkbox"]:checked:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Increase the clickable area */
.admin-table-cell {
  position: relative;
}

.admin-table-cell {
  flex: 1;
  flex-wrap: wrap;
  border-right: 1px solid var(--color-border);
  padding: 8px;
  font-size: var(--font-size-secondary);
  font-weight: 500;
}

.admin-table-cell:last-child {
  border-right: none;
}

.admin-table-header .admin-table-cell {
  border-right: 1px solid var(--color-border);
}

.admin-table-body .admin-table-row:hover {
  background-color: #f1f1f1;
}

button {
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: var(--color-primary-accent);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  border-radius: 20px;
  font-weight: 600;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Dropdown menu style */
.admin-action-menu {
  display: block;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #f7f5ff;
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 250px;
  transition: opacity 0.3s;
}
.admin-action-menu li {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-secondary);
  font-weight: 600;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

/* Tablet/Intermediate */
@media (min-width: 768px) and (max-width: 1500px) {
  .br__novel__sc__three {
    width: 100%;
    overflow: scroll;
  }
  .admin-table {
    margin: 30px 0;
    min-width: 1200px;
  }
  .admin-table-cell:first-child {
    flex: 0.2;
  }
}

/* Mobile */
@media all and (max-width: 767px) {
  .br__novel__sc__one > h1 {
    font-size: 17px;
    font-weight: 600;
  }
  .br__novel__sc__three {
    width: 100%;
    overflow: scroll;
  }
  .admin-table {
    margin: 30px 0;
    min-width: 1200px;
  }
  .admin-table-cell:first-child {
    flex: 0.2;
  }
}
