.ana__section__one {
  width: 100%;
}
.ana__section__one > h1 {
  background-color: #f2994a;
  color: white;
  width: 100%;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: var(--font-size-primary);
  font-weight: 500;
}
.ana__section__one__form__wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}
.ana__section__one__form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 0;
  width: 100%;
}
.ana__section__one__form span {
  width: 46%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ana__section__one__form label {
  font-size: 15px;
  font-weight: 500;
}
.ana__section__one__form input {
  border: 1px solid #f2994a;
  border-radius: 10px;
  padding: 10px;
}
.ana__section__one__form select {
  border: 1px solid #f2994a;
  border-radius: 10px;
  padding: 10px;
}

@media all and (max-width: 768px) {
  .ana__section__one__form span {
    width: 100%;
  }
}
