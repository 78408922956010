.bp__section__two {
  background: #fff;
  padding: 1.5% 1.5%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.bp__section__two__chapter {
  font-size: 18px;
  color: var(--color-text-secondary);
  font-weight: 600;
  margin-bottom: 20px;
}
.bp__section__two__info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.bp__section__two__text {
  font-size: 15px;
  line-height: 40px;
  margin-bottom: 20px;
}
.bp__section__two__text > br {
  margin: 30px 0;
}
.bp__section__two > span {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: auto;
}
.bp__section__two > span > button {
  border: 1px solid var(--color-secondary-accent);
  background: transparent;
  color: var(--color-secondary-accent);
  background-color: #d4edda;
  color: #155724;
  border-radius: 35px;
  padding: 10px 20px;
  display: flex;
  align-self: center;
  text-align: center;
}

@media all and (max-width: 1024px) {
  .bp {
    padding: 5% 5%;
  }
  .bp__section__one {
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 5% 5%;
  }
  .bp__section__two {
    padding: 5% 2%;
  }
  .bp__section__two__chapter {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }
}
