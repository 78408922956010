.br__dashboard {
  min-height: 100vh;
  padding: 0 5%;
}

.br__dashboard__sc__one {
  margin: 20px 0;
}
.br__dashboard__sc__one > h1 {
  font-size: 20px;
  font-weight: 600;
}
.br__dashboard__sc__two {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.br__dashboard__sc__two__block {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 23%;
  border-radius: var(--border-radius-medium);
  padding: 20px 20px;
}
.br__dashboard__sc__two__block__start {
  background: white;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.br__dashboard__sc__two__block__start__icon {
  font-size: 25px;
}
.br__dashboard__sc__two__block__end {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
}
.br__dashboard__sc__two__block__end > h1 {
  font-size: var(--font-size-primary);
  font-weight: 400;
}
.br__dashboard__sc__two__block__end > p {
  font-size: var(--font-size-primary);
  font-weight: 600;
}
.br__dashboard__sc__three {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.br__dashboard__sc__three__start {
  width: 40%;
}
.br__dashboard__sc__three__start > p {
  font-size: 13px;
  margin-bottom: 20px;
}
.br__dashboard__sc__three__start > img {
  width: 500px;
}
.br__dashboard__sc__three__end {
  width: 49%;
}
.br__dashboard__sc__three__end > p {
  font-size: 13px;
  margin-bottom: 20px;
}
.br__dashboard__sc__three__end > img {
  width: 500px;
}

/* Tablet/Intermediate */
@media (min-width: 768px) and (max-width: 1250px) {
  .br__dashboard__sc__two__block {
    width: 48%;
    padding: 20px 10px;
    margin-bottom: 10px;
  }
}

/* Mobile */
@media all and (max-width: 768px) {
  .br__dashboard__sc__two {
    gap: 2%;
  }
  .br__dashboard__sc__two__block {
    width: 48%;
    padding: 20px 10px;
    margin-bottom: 10px;
  }
  .br__dashboard__sc__two__block__end > h1 {
    font-size: 13px;
    font-weight: 400;
  }
  .br__dashboard__sc__two__block__end > p {
    font-size: 13px;
    font-weight: 600;
  }
  .br__dashboard__sc__three {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
  }
  .br__dashboard__sc__three__end {
    width: 100%;
  }
  .br__dashboard__sc__three__start {
    width: 100%;
  }
  .br__dashboard__sc__three__start > img {
    width: 100%;
  }
  .br__dashboard__sc__three__end > img {
    width: 100%;
  }
}
