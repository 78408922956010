.aas {
  padding: 20px 5%;
}
.aas__section__one {
  margin-top: 30px;
}
.aas__section__one > h1 {
  font-size: var(--font-size-primary);
  font-weight: 500;
  margin-bottom: 20px;
}
