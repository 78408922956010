.not__found {
  min-height: 100vh;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-medium);
  text-align: center;
}
.not__found > img {
  object-fit: contain;
  width: 200px;
}
.not__found > h1 {
  font-weight: 500;
  font-size: var(--font-size-h3);
  color: var(--color-text-primary);
}
.not__found > p {
  font-weight: 400;
  font-size: var(--font-size-primary);
  color: var(--color-text-secondary);
  width: 30vw;
}
.not__found__link {
  font-weight: 500;
  color: var(--color-primary-accent);
  cursor: pointer;
}

/* Tablet/Intermediate */
@media (min-width: 768px) and (max-width: 1023px) {
  .not__found > p {
    width: 50vw;
  }
}

/* Mobile */
@media all and (max-width: 768px) {
  .not__found > p {
    width: 90vw;
  }
}
