.admin {
  transition: 250ms ease-in-out;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.admin__container {
  display: flex;
  flex: 1 1;
  height: 0;
}
.admin__outlet {
  position: relative;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  border-radius: var(--border-radius-medium);
  overflow-y: auto;
  padding-bottom: 96px;
}
.admin__outlet.active {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-left: 0%;
  height: 100%;
  padding-left: 75px;
}
.admin__navbar {
  position: relative;
  height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 22.5%;
  background: #f7f5ff;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.admin__navbar.active {
  display: none;
}
.admin__navbar.active > .admin__navbar__section__two > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.admin__navbar.active > .admin__navbar__section__two > div > h3 {
  display: none;
}
.admin__navbar.active > .admin__navbar__section__three > div > h3 {
  display: none;
}
.admin__navbar.active > .admin__navbar__section__one {
  display: flex;
  justify-content: center;
}
.admin__navbar__section__two {
}
.admin__navbar__section__two > div {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  cursor: pointer;
  color: black;
  width: 100%;
}
.admin__navbar__icon {
  font-size: 18px;
  color: var(--color-text-primary);
}
.admin__navbar h3 {
  font-size: var(--font-size-secondary);
  font-weight: 400;
  margin-left: 10px;
  color: var(--color-text-primary);
}
.admin__navbar__active > h3 {
  font-size: var(--font-size-secondary);
  margin-left: 10px;
  color: var(--color-primary-accent);
}
.admin__navbar__active {
  background: var(--color-primary-accent);
  color: var(--color-background);
  border-radius: var(--border-radius-small);
}
.admin__navbar__active > .admin__navbar__icon {
  color: var(--color-background);
}
.admin__navbar__active > .admin__navbar__icon__submenu {
  color: var(--color-background);
}
.admin__navbar__novels__icon {
  margin-left: auto;
  color: var(--color-text-primary);
  font-size: 22px;
  cursor: pointer;
}
.admin__navbar__novels__icon--active {
  color: var(--color-background);
}
.admin__navbar__novels__display__icon {
  color: var(--color-text-primary);
  cursor: pointer;
}
.admin__navbar__novels__display__icon--active {
  color: var(--color-background);
  font-size: var(--font-size-secondary);
  cursor: pointer;
}
.admin__navbar__novels__display__text {
  font-size: var(--font-size-secondary);
  font-weight: 400;
  margin-left: 10px;
}
.admin__navbar__novels__display__text--active {
  font-size: var(--font-size-secondary);
  font-weight: 500;
  margin-left: 4px;
  color: #fff;
}
.admin__navbar__novels__wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}
.admin__navbar__novels__wrap--active {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.admin__navbar__novels__display {
  display: flex;
  width: 100%;
  gap: 4px;
}
.admin__navbar__novels__accordion {
  display: none;
  background: blue;
}

.admin__navbar__novels__accordion.active {
  display: block;
  background: white;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}
.admin__navbar__novels__accordion h3 {
  width: 100%;
  padding: 15px 0;
  margin: 5px 0;
  color: gray !important;
  display: flex;
  width: 100%;
  gap: 10px;
  border-top: 1px solid gray;
}
.admin__navbar__novels__accordion.active h3 {
  border-top: 1px solid lightgray;
}
.admin__navbar__active > span {
  color: var(--color-background);
}
.admin__navbar__active > h3 {
  color: var(--color-background);
}

/* Tablet/Intermediate */
@media (min-width: 768px) and (max-width: 1023px) {
  .admin__navbar {
    width: 40%;
  }
}

/* Mobile */
@media all and (max-width: 768px) {
  .admin__navbar {
    width: 100%;
  }
  .admin__navbar__section__two > div {
    padding: 20px 10px;
  }

  .admin__outlet {
    display: none;
  }
  .admin__outlet.active {
    display: block;
    padding: 0;
  }
}
