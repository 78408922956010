.br__settings__sc__one > div {
  margin: 15px 0;
  padding: 20px 20px;
  background: #f7f5ff;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 50%;
  cursor: pointer;
}
.toggle__container {
  display: flex;
  align-items: center;
}
.toggle {
  visibility: hidden;
}
.toggle + label {
  display: flex;
  align-items: center;
  font-size: var(--secondary-font-size);
  cursor: pointer;
  color: var(--primary-text-color);
}
.toggle + label::before {
  content: "";
  height: 15px;
  width: 30px;
  border-radius: 10px;
  background-color: #c7ccd0;
  margin-right: 10px;
  transition: background-color 250ms ease-in-out, transform 250ms ease-in-out;
}
.toggle + label::after {
  content: "";
  height: 13px;
  width: 13px;
  border-radius: 100%;
  background-color: #333;
  position: absolute;
}
.toggle:checked + label::after {
  transform: translateX(100%);
}

@media all and (max-width: 768px) {
  .br__settings__sc__one > div {
    width: 100%;
  }
}
