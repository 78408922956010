.one-table-button {
  background-color: #cccccc;
  line-height: 1.2em;
  font-size: 14px;
  font-weight: 600;
  color: black;
  flex-direction: row;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  transition: color 0.5s, background-color 0.5s;
}

.one-table-button:hover {
  background-color: #dddddd;
}

.one-table-button:active {
  color: #ff7700;
}

.one-table-button-primary {
  background-color: #ff7700;
  color: white;
}
.one-table-button-primary:hover {
  background-color: #ffaa33;
}

.one-table-button-primary-outlined {
  border: 1px solid #ff7700;
  background-color: transparent;
  color: #ff7700;
}
.one-table-button-primary-outlined:hover {
  background-color: #ffddaa;
}
.one-table-button-primary-outlined:active {
  border-width: 2px;
}
.one-table-button-outlined {
  border: 1px solid #777777;
  background-color: transparent;
  color: #777777;
}
.one-table-button-outlined:hover {
  background-color: #dddddd;
}
.one-table-button-outlined:active {
  border-width: 2px;
}
