.no__result {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;
  background-color: inherit;
  text-align: center;
}

.no__result > h1 {
  font-size: 18px;
  font-weight: 400;
  color: #121212;
  width: 300px;
  margin: 10px 0;
}
.no__result > p {
  font-size: var(--font-size-secondary);
  font-weight: 400;
  color: #777;
  width: 300px;
}

@media all and (max-width: 768px) {
  .no__result > h1 {
    width: 100%;
  }
  .no__result > p {
    width: 100%;
  }
}
