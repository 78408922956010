.br__header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  background: #f7f5ff;
  top: 0;
  left: 0;
  z-index: 10;
}
.br__menu {
  display: none;
  font-size: 25px;
}
.br__sc__one {
  padding: 0;
}
.br__sc__one > img {
  object-fit: cover;
  height: 35px;
}
.br__header__sc__two {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 40px;
}
.br__header__sc__two__search {
}
.br__header__sc__two__search > input {
  min-width: 400px;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #f2994a;
}
.br__header__sc__two__profile {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.br__header__sc__two__profile > img {
  object-fit: cover;
  height: 35px;
  width: 35px;
  border-radius: 100%;
}
.br__header__sc__two__profile > p {
  font-size: 13px;
  font-weight: 500;
}
.br__header__sc__two__notification__icon {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

/* Tablet/Intermediate */
@media (min-width: 768px) and (max-width: 1023px) {
  .br__header__sc__two__search {
    display: none;
  }
}

/* Mobile */
@media all and (max-width: 768px) {
  .br__menu {
    display: block;
  }
  .br__sc__one > img {
    display: none;
  }
  .br__header__sc__two__search {
    display: none;
  }
}
