/* Normalize or Reset CSS */
@import-normalize;

@font-face {
  font-family: primaryFont;
  src: url(/src/assets/fonts/AirbnbCereal_W_Lt.otf);
}

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Root Font Size */
html {
  font-size: 16px; /* 1rem = 16px */
}


/* Body Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  background-color: var(--color-background);
  color: var(--color-text-primary);
}

/* Code Styles */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Root Variables */
:root {
  /* Colors */
  --color-background: #ffffff;
  --color-primary-accent: #f58634;
  --color-secondary-accent: #669933;
  --color-text-primary: #333333;
  --color-text-secondary: #808080;
  --color-text-tertiary: #cccccc;
  --color-text-quaternary: #ffffff;
  --color-text-error: #D32F2F;
  --color-text-background-color: #FFEBEE;
  --color-border: #fff6ef;
  --color-border-dimmed: #fff6eff0;

  /* Typography */
  --font-size-primary: 1rem; /* 16px */
  --font-size-secondary: 0.875rem; /* 14px */
  --font-size-tertiary: 0.75rem; /* 12px */

  /* Header Font Sizes */
  --font-size-h1: 2.5rem; /* 40px */
  --font-size-h2: 2rem; /* 32px */
  --font-size-h3: 1.75rem; /* 28px */
  --font-size-h4: 1.5rem; /* 24px */
  --font-size-h5: 1.25rem; /* 20px */
  --font-size-h6: 1rem; /* var(--font-size-primary) */

  /* Border Radius */
  --border-radius-small: 0.25rem; /* 4px */
  --border-radius-medium: 0.5rem; /* 8px */
  --border-radius-large: 0.75rem; /* 12px */
  --border-radius-xlarge: 1rem; /* 16px */
  --border-radius-round: 50%; /* Fully rounded for circles */

  /* Spacing */
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 2rem;


  /* Box Shadows */
  --box-shadow-small: 0 1px 3px rgba(0, 0, 0, 0.1);
  --box-shadow-medium: 0 3px 6px rgba(0, 0, 0, 0.15);
  --box-shadow-large: 0 10px 20px rgba(0, 0, 0, 0.2);

  /* Line Height */
  --line-height-primary:   1.75rem /* 28px */
  --line-height-secondary: 1.25rem /* 20px */
  --line-height-tertiary: 1.25rem /* 20px */
}



/* Accessibility Enhancements */
a:focus,
/* button:focus, */
input:focus,
textarea:focus {
  outline: 1px solid var(--color-primary-accent);
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.mt-1 {
  margin-top: var(--spacing-medium);
}

.mb-1 {
  margin-bottom: var(--spacing-medium);
}
.mr-1 {
  margin-right: var(--spacing-medium);
}
.ml-1 {
  margin-left: var(--spacing-medium);
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-medium);
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;  /* Vertical scrollbar width */
  height: 10px; /* Horizontal scrollbar height */
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: #f1f1f3;  /* Light gray background */
  border-radius: 10px;
  transition: background 0.3s ease;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #6E7CA0;  /* Soft blue-gray */
  border-radius: 10px;
  transition: background 0.3s ease;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4A5568;  /* Darker shade on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6E7CA0 #f1f1f3;
}

