.login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login__container {
  padding: 20px 20px;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff6eff0;
  border-radius: 10px;
  width: 403px;
  min-height: 524px;
  min-height: max-content;
}
.login__container > img {
  width: 190px;
  height: 37.15px;
  margin-bottom: 20px;
}
.login__container > h1 {
  font-weight: 500;
  font-size: var(--font-size-primary);
  line-height: 24px;
  color: var(--color-text-primary);
  margin-bottom: 20px;
}

.login__container__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login__container__form > input {
  margin: 10px 0;
  border-radius: 30px;
  height: 40px;
  width: 100%;
  border: 0;
  font-size: var(--font-size-secondary);
  line-height: 24px;
  padding: 10px;
  background-color: transparent;
  color: var(--color-text-secondary);
  border: 1px solid #ddd;
  font-weight: 500;
}
.login__container__form > button {
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
  height: 46px;
  border: 0;
  background: var(--color-primary-accent);
  color: white;
}
.login__container__form > p {
  font-size: var(--font-size-secondary);
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 24px;
  text-align: center;
}
.login__container__form > p > span {
  font-weight: 600;
  color: var(--color-primary-accent);
  cursor: pointer;
}
.login__container > p {
  font-size: var(--font-size-secondary);
  line-height: 24px;
  text-align: center;
  margin-top: auto;
}
.login__container > p > span {
  font-weight: 600;
  color: var(--color-primary-accent);
  cursor: pointer;
}
.login__error__message {
  width: 100%;
  color: var(--color-text-error);
  font-size: var(--font-size-secondary);
  line-height: 25px;
  font-weight: 500;
  margin-top: 10px;
}

/* Tablet/Intermediate */
@media (min-width: 768px) and (max-width: 1023px) {
  .login__container {
    width: 50vw;
  }
}

/* Mobile */
@media all and (max-width: 768px) {
  .login__container {
    width: 90vw;
  }
}
